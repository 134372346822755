<template>
  <!-- 
    画面: 画像カテゴリ編集
    用途: 画像カテゴリを更新または削除する
   -->
  <div>
    <!-- #region Main -->
    <CRow>
      <CCol md="12" lg="12">
        <CCard>
          <CCardHeader>
            {{ $t("imageCategoryEditPage.pageTitle") }}
          </CCardHeader>

          <CCardBody>
            <span class="caption">
              {{ $t("imageCategoryEditPage.caption") }}
            </span>
            <CForm class="submit_form">

              <!-- #region 入力フィールド -->

              <!-- #region 名称 -->
              <CRow>
                <CCol class="label"
                  ><strong class="required-mark">{{
                    $t("imageCategoryEditPage.name")
                  }}</strong></CCol
                >
                <CCol>
                  <CInput v-model="edit.name" />
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.required"
                  >
                    {{
                      $t("validations.required", {
                        vName: $t("imageCategoryEditPage.name"),
                      })
                    }}
                  </p>
                  <p
                    class="text-danger input-info"
                    v-if="$v.edit.name.$dirty && !$v.edit.name.maxLength"
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("imageCategoryEditPage.name"),
                        vMax: $v.edit.name.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 名称 -->

              <!-- #region 説明 -->
              <CRow>
                <CCol class="label"
                  ><strong>{{
                    $t("imageCategoryEditPage.description")
                  }}</strong></CCol
                >
                <CCol>
                  <CTextarea class="mb-2" v-model="edit.description" />
                  <p
                    class="text-danger input-info"
                    v-if="
                      $v.edit.description.$dirty &&
                      !$v.edit.description.maxLength
                    "
                  >
                    {{
                      $t("validations.maxLength", {
                        vName: $t("imageCategoryEditPage.description"),
                        vMax: $v.edit.description.$params.maxLength.max,
                      })
                    }}
                  </p>
                </CCol>
              </CRow>
              <!-- #endregion 説明 -->

              <!-- #endregion 入力フィールド -->

              <!-- #region 更新ボタン -->
              <CButton
                class="px-5 mt-4 float-left"
                color="info"
                @click="onUpdateClicked()"
                v-bind:class="activeStatus"
              >
                <i class="cil-task"></i> {{ $t("common.update") }}
              </CButton>
              <!-- #endregion 更新ボタン -->

              <!-- #region 削除ボタン -->
              <CButton
                class="px-5 mt-4 float-right"
                color="danger"
                @click="onDeleteClicked()"
              >
                <i class="cil-trash"></i> {{ $t("common.delete") }}
              </CButton>
              <!-- #endregion 削除ボタン -->
            </CForm>
          </CCardBody>

          <CCardFooter>
            <!-- #region 戻るボタン -->
            <CButton
              color="secondary"
              class=""
              @click="onBackClicked()"
            ><i class="icon cil-arrow-left mr-2"></i>{{ $t("common.back") }}</CButton>
            <!-- #endregion 戻るボタン -->
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
    <!-- #endregion Main -->

    <DeleteConfirmModal
        :deleteConfirmModal="deleteConfirmModal"
        :callback="reqDelete"
        @close="deleteConfirmModal = $event"
      />

    <SuccessModal
      :successModal="successModal"
      @close="successModal = $event"
    />

    <!-- <SuccessModal :successModal="successModal" @close="successModal = $event" /> -->

    <ErrorModal
      :errorModal="errorModal"
      :errorMessage="errorMessage"
      @close="errorModal = $event"
    />
  </div>
</template>

<script>
// import RoomTypeListTable from "../../components/RoomTypeListTable";
// import axios from "axios";
import axios from "axios";
import SuccessModal from "@/components/SuccessModal";
import ErrorModal from "@/components/ErrorModal";
import DeleteConfirmModal from "@/components/DeleteConfirmModal";


import {
  required,
  // numeric,
  // minLength,
  maxLength,
  // helpers,
} from "vuelidate/lib/validators";

export default {
  name: "ImageCategoryEdit",

  components: {
    // RoomTypeListTable
    SuccessModal,
    ErrorModal,
    DeleteConfirmModal,
  },

  computed: {
    activeStatus() {
      console.log(this.$v);
      return this.$v.edit.$invalid ? "inactive" : "active";
    },
  },

  data() {
    return {
      //#region Flag

      loading: false,
      successModal: false,
      errorModal: false,
      deleteConfirmModal: false,

      //#endregion Flag

      //#region Message
      errorMessage: "error",
      //#endregion Message

      //#region Input

      edit: {
        name: "",
        description: "",
        language: "",
      },

      //#endregion Input

      //#region Response data

      response: [],
      currentdata: [],
      //#endregion Response data
    };
  },

  validations() {
    return {
      edit: {
        name: {
          required,
          maxLength: maxLength(50),
        },
        description: {
          maxLength: maxLength(1000),
        },
      },
    };
  },

  methods: {
    //#region Event

    /** 戻るボタン押下 */
    onBackClicked() {
      this.$router.push({
        path: `/operator/image-category/list`,
      });
    },

    /** 削除ボタン押下 */
    onDeleteClicked() {
      this.deleteConfirmModal = true;
    },

    /** 削除ボタン押下 */
    onDeleteSubmit() {
      this.reqDelete();
    },

    /** 更新ボタン押下 */
    onUpdateClicked() {
      // this.$v.edit.$invalid ? this.$v.edit.$touch() : this.putRoomType();

      var imageCategoryId = this.$route.params.imageCategoryId;
      
      var url = "/rest/imagecategory/" +imageCategoryId;
      let reqData = [this.edit];
      reqData[0].language = this.$i18n.locale;

      console.log(url);
      console.log(reqData);
      // this.successModal = true;
      axios
        .put(url, reqData, {
          headers: { "Accept-Language": "*" },
        })
        .then((resp) => {
          //console.log(JSON.stringify(resp.data));
          this.successModal = true;
          this.getImageCategory();
          console.log(resp);


        })
        .catch((err) => {
          this.errorMessage = err + "'  post to facility '";
          this.errorModal = true;
          console.log(err);
        });

    },

    //#endregion Event

    //#region Request
    getImageCategory(categoryId) {
      
      const url = `/rest/imagecategory/${categoryId}`;
      
      axios

                .get(url, {
          
          headers: { "Accept-Language": this.$i18n.locale },
          
        })
        .then((resp) => {
          // const datas = resp.data;
          const datas = resp.data.categories;
          console.log(datas);
          
          this.currentdata = datas;
          // 取得データを一覧表に流し込み

          this.loading = false;
        })
        .catch((err) => {
          console.log(err);
          this.loading = false;
        });
    },

    //#endregion

    /**
     * delete single image
     */
    reqDelete() {
      
      this.deleteConfirmModal = false;

      var imageCategoryId = this.$route.params.imageCategoryId;
      var url = `/rest/imagecategory/${imageCategoryId}`;
      console.log(url);
      
      axios
        .delete(url, {
          
          headers: { "Accept-Language": this.$i18n.locale },
          
        })
        .then((resp) => {
          console.log(JSON.stringify(resp.data));
          this.successModal = true;
          this.getImageCategory();


        })
        .catch((err) => {
          this.errorMessage = err + "'  post to facility '";
          this.errorModal = true;
          console.log(err);
        });
    },

    putRoomType() {
      this.successModal = true;

      const imageCategoryId = this.$route.params.imageCategoryId;
      const url = `/rest/imagecategory/${imageCategoryId}`;
      const body = this.edit;
      console.log(url);
      console.log(body);
      // TODO: API開発後に実装する
    },
  },
  beforeMount() {
    console.log(this.$route.params);
    console.log(this.$route.params.imageCategoryMasterId);
    console.log(this.$route.params.imageCategoryId);
    const categoryId = this.$route.params.imageCategoryId;
    this.getImageCategory(categoryId);
  
  },
};
</script>
